<template>
  <div class="default-personal">
    <div style="height: 210px"></div>
    <div class="personal-box">
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'personal-view',
    created() {
      this.$store.dispatch('getSeaArea')
    }
  }
</script>

<style lang="scss" scoped>
  @import "./personal-view.scss";
</style>
